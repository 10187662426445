body {
  background-color: #fff;
}

#livingposter-loading-helper {
  display: none;
}

.livingposter-logo {
  width: 300px;
  height: 207px;
  margin: 5px auto;
  min-height: 70px;
  position: relative;
  background-image: url('../../images/livingposter_logo.svg');
  background-repeat: no-repeat;
  background-size: contain;

  & > svg {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .livingposter-splash-large-flex-space {
    margin: 0 auto;
    flex-grow: 3;
  }
}

.livingposter-splash-small-flex-space {
  margin: 0 auto;
  flex-grow: 1;
  max-height: 70px;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: 'LivingLight';
}

#livingposter-splash {
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: #333333;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 100;

  transition: all 0.5s ease-in-out;
}

.start #livingposter-splash {
  top: 100%;
}

#livingposter-privacy {
  padding-bottom: 15px;
  color: white;
  opacity: 0.5;
}

#livingposter-privacy > a {
  font-family: 'LivingLight';
  font-size: 10px;
  color: white;
  text-decoration: none;
  cursor: pointer;
  letter-spacing: 1px;
}

#livingposter-loading {
  margin: 5px auto;
  height: 52px;
  min-height: 52px;
}

#livingposter-splash.livingposter-started {
  animation: zee-splash-hide 400ms ease-in-out forwards;
}

#livingposter-splash > #livingposter-loading {
  display: none;
}

#livingposter-splash.livingposter-loading > #livingposter-launch-button {
  display: none;
}

#livingposter-splash.livingposter-loading > #livingposter-loading {
  display: block;
}

#livingposter-launch-button {
  margin: 5px auto;
  height: 52px;
  min-height: 52px;
  position: relative;
  background: none;
  outline: none;
  border: 1px solid #979797;
  border-radius: 1px;
  padding: 0px 35px 0px 40px;

  & > span {
    font-size: 14px;

    font-family: 'LivingLight';
    color: white;
    text-transform: uppercase;
    padding-right: 10px;
  }

  & > svg {
    width: 11px;
    vertical-align: middle;
    position: relative;
    top: -2px;
  }
}

#livingposter-cookie-notice {
  font-family: 'LivingLight';
  color: white;
  font-size: 10px;
  text-align: center;
  max-width: 250px;
  opacity: 0.5;
  letter-spacing: 1px;
}

#livingposter-cookie-title {
  display: block;
  text-transform: uppercase;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
}

html,
body {
  margin: 0;
  overflow: hidden;
}

html {
  font-family: sans-serif;
}

#loading {
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 9999999;
}

#loading img {
  height: 5em;
}

#loading span {
  color: black;
  font-weight: bold;
}

.ui {
  position: fixed;

  margin: 0.5rem;

  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 6px;
}

.stats {
  top: 0;
  left: 0;
  z-index: 200;
  margin: 0.5rem;
  padding: 0.5rem 0.5rem 0;
}

.stats-item {
  margin: 0 0 0.5rem;
}

.stats-item-title {
  margin: 0 0 0.25rem;

  font-size: 0.75rem;
}

#stats div {
  position: relative !important;
}

.marker {
  right: 0;
  bottom: 0;
  z-index: 200;
  margin: 0.5rem;
  padding: 0.25rem 0.5rem;

  font-size: 0.75rem;
  color: inherit;
  text-decoration: none;
}

#dispose {
  right: 25rem;
  bottom: 0;
  z-index: 200;
  margin: 0.5rem;
  padding: 0.25rem 0.5rem;

  font-size: 0.75rem;
  color: red;
  background-color: rgba(210, 30, 0, 0);
  text-decoration: none;

  cursor: pointer;
}

#app {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

#video {
  position: absolute;
  top: 0;
  left: 0;

  display: block;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}

#canvas {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;

  display: block;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}

#arvideo {
  display: none;
}
